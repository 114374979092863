<template>
  <SidebarBase language-switcher>
    <template #main>
      <div class="flex-1">
        <ButtonSidebar
          label="Dashboard"
          :active="section == 'dashboard'"
          :to="`${manage ? '/manage' : ''}/justification/${absenceKey}/dashboard`"
        >
          <template #svg>
            <fw-icon-dashboard class="w-6 h-6" />
          </template>
        </ButtonSidebar>

        <ButtonSidebar
          :label="$t('justification')"
          :active="!section"
          :to="`${manage ? '/manage' : ''}/justification/${absenceKey}`"
        >
          <template #svg>
            <fw-icon-article class="w-6 h-6" />
          </template>
        </ButtonSidebar>

        <ButtonSidebar
          :label="$t('comments')"
          :to="`${manage ? '/manage' : ''}/justification/${absenceKey}/comments`"
          :active="section == 'comments'"
        >
          <template #svg>
            <fw-icon-message class="w-6 h-6 " />
          </template>
        </ButtonSidebar>

        <ButtonSidebar
          :label="$t('activity')"
          :to="`${manage ? '/manage' : ''}/justification/${absenceKey}/activity`"
          :active="section == 'activity'"
        >
          <template #svg>
            <fw-icon-activity class="w-6 h-6 " />
          </template>
        </ButtonSidebar>

        <ButtonSidebar
          :label="$t('notifications')"
          :to="`${manage ? '/manage' : ''}/justification/${absenceKey}/notifications`"
          :active="section == 'notifications'"
        >
          <template #svg>
            <fw-icon-message-sent class="w-6 h-6 " />
          </template>
        </ButtonSidebar>
      </div>
    </template>
  </SidebarBase>
</template>

<script>
import SidebarBase from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarBase'
import ButtonSidebar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonSidebar'

export default {
  components: {
    ButtonSidebar,
    SidebarBase,
  },
  props: {
    section: {
      type: String,
      default: null,
    },
    manage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },

    permissions() {
      return this.$store.getters.userPermissions
    },

    language() {
      return this.$store.state.language
    },

    absenceKey() {
      return this.$route.params.key
    },
  },
}
</script>

<style lang="postcss">
a span:last-child {
  @apply text-black;
}
</style>

<i18n>
{
  "pt": {
    "activity": "Atividade",
    "notifications": "Notifcações",
    "comments": "Comentários",
    "justification": "Justificação"
  },
  "en": {
    "activity": "Activity",
    "notifications": "Notifications",
    "comments": "Comments",
    "justification": "Justification"
  }
}
</i18n>
