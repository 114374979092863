<template>
  <div>
    <fw-heading size="xl">Dashboard</fw-heading>
    <DashboardHero :title-label="'Titulo'" class="mt-2 mb-5">
      <template #title>
        <fw-heading size="h1">
          <span>#{{ justification.key }}</span>
        </fw-heading>
      </template>
      <template v-if="justification.status != 'draft' && justification.status != 'submitted'" #footer>
        <div class="text-center">
          <fw-label color="text-white opacity-50">{{ $t('absenceComunication.label') }}</fw-label>
          <div>
            {{ $t(`absenceComunication.${justification.absence_communication}`) }}
            {{ justification.absence_communication_date | formatDate }}
          </div>
        </div>
        <div v-if="justification.approved != null" class="text-center">
          <fw-label color="text-white opacity-50">Aprovada</fw-label>
          <div>
            {{ justification.approved == true ? 'Sim' : 'Não' }}
          </div>
        </div>
      </template>
    </DashboardHero>

    <fw-panel :title="$t('status.label')" class="my-5">
      <div class="flex flex-col gap-4">
        <div class="flex gap-3">
          <template v-for="(status, key) in justificationStatus">
            <div
              v-if="
                justification.status != 'closed' || (justification.status == 'closed' && key === justification.status)
              "
              :key="key"
              :class="{
                'w-2/5 border-primary min-h-28': status.isActive && justification.status != 'closed',
                'w-1/5 border-white min-h-28': justification.status != key,
                'w-full border-white': justification.status == 'closed',
              }"
              class="relative rounded-xl bg-white border p-4 flex flex-col text-center font-semibold items-center gap-1 cursor-pointer group"
              @click="openStatusInfoModal({ key: key, meta: status })"
            >
              <div class="w-full flex items-center justify-center">
                <div v-if="status.isDone">
                  <fw-icon-check-solid class="w-6 h-6 flex-shrink-0 text-primary" />
                </div>
                <div v-else-if="status.isActive">
                  <fw-icon-run class="w-6 h-6 flex-shrink-0 text-primary animate-pulse" />
                </div>
                <div v-else>
                  <fw-icon-rest class="w-6 h-6 flex-shrink-0 text-gray-300" />
                </div>
              </div>
              <div
                class="flex-1"
                :class="{
                  'font-bold text-primary': status.isActive || status.isDone,
                  'font-bold': status.canChange,
                  'font-medium': status.isDone && !status.isActive,
                  'text-gray-500': !status.isDone && !status.isActive,
                }"
              >
                {{ status.label }}
              </div>
              <div v-if="justification.status != 'closed'" class="flex justify-center items-center font-normal text-xs">
                <div v-if="status.isActive && key != 'closed'" class="text-primary">
                  {{ $t('phaseOngoing') }}
                </div>
                <div v-else-if="!status.canChange && status.isDone && key != 'closed'" class="text-primary">
                  {{ $t('phaseFinished') }}
                </div>
                <div v-else-if="status.canChange && !status.isDone" class="text-primary">
                  {{ $t('phaseAvailable') }}
                </div>
                <div v-else-if="key != 'closed'" class="text-gray-400">
                  {{ $t('phasePending') }}
                </div>
              </div>
              <div class="absolute top-2 right-2">
                <fw-icon-info class="w-4 h-4 text-gray-300 group-hover:text-gray-400" />
              </div>
            </div>
          </template>
        </div>
        <fw-panel-info v-if="justification.status != 'closed'" clean :icon="false" centered size="xs"
          >{{ $t('statusInfo') }}.</fw-panel-info
        >
      </div>
    </fw-panel>

    <fw-panel :title="$t('calendar')" boxed="xs" custom-class="bg-white" class="my-5">
      <div class="flex-1">
        <fw-label>Intervalos de Datas</fw-label>
        <div v-if="justification.dates && justification.dates.length" class="font-medium flex gap-2 items-center">
          <!-- <fw-icon-calendar class="w-5 h-5 text-primary opacity-100" /> -->
          <fw-tag v-for="(date, i) in justification.dates" :key="i" size="sm" type="primary">
            {{ date.start_at | formatDate }} a {{ date.end_at | formatDate }}
          </fw-tag>
        </div>
        <div v-else class="font-medium flex gap-2 items-center text-sm text-gray-500">
          {{ $t('notDefined') }}
        </div>
      </div>
      <div v-if="Boolean(selectedJustificationType.withTime)">
        <fw-label>Horas</fw-label>
        <div v-if="justification.start_time && justification.end_time" class="font-medium flex gap-2 items-center">
          <fw-icon-clock class="w-5 h-5 opacity-50" /> {{ justification.start_time | formatTime }}h a
          {{ justification.end_time | formatTime }}h
        </div>
        <div v-else class="font-medium flex gap-2 items-center text-sm text-gray-500">
          {{ $t('notDefined') }}
        </div>
      </div>
    </fw-panel>

    <fw-panel :title="$t('people')" class="my-5">
      <div class="flex justify-items-stretch gap-3">
        <div class="bg-white rounded-xl px-4 py-2 flex-1">
          <fw-label>{{ $t('manager') }}</fw-label>
          <Person v-if="justification.manager" :no-style="true" :person="users[justification.manager]"></Person>
          <div v-else class="text-sm text-gray-500 flex-1 items-center flex">
            {{ $t('notDefined') }}
          </div>
        </div>

        <div class="bg-white rounded-xl px-4 py-2 flex gap-10 flex-1">
          <div class="flex flex-col">
            <fw-label>{{ $t('subManagers') }}</fw-label>
            <div v-if="justification.sub_managers && justification.sub_managers.length" class="flex gap-3">
              <Person
                v-for="sub_manager_key in justification.sub_managers"
                :key="sub_manager_key"
                :no-style="true"
                :person="users[sub_manager_key]"
                :shortname="true"
              ></Person>
            </div>
            <div v-else class="text-sm text-gray-500 flex-1 items-center justify-center flex">
              {{ $t('notDefined') }}
            </div>
          </div>
        </div>
      </div>
    </fw-panel>

    <b-modal
      :active="Boolean(activeStatusInfo)"
      scroll="keep"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="700"
      :on-cancel="closeModal"
      :custom-class="'rounded-buefy-modal'"
    >
      <div v-if="activeStatusInfo" class="h-full flex flex-col p-5 gap-5">
        <fw-panel>
          <div class="flex flex-col gap-2">
            <fw-heading size="h2">
              <span class="font-medium text-gray-500">{{ $t('status.title') }} </span> {{ activeStatusInfo.meta.label }}
            </fw-heading>
            <div v-html="$t(`activeStatusInfoText.${activeStatusInfo.key}`)"></div>
          </div>
        </fw-panel>
        <div class="flex items-center justify-end gap-5">
          <fw-button type="link-muted" @click.native="closeModal">{{ $t('close') }}</fw-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DashboardHero from '@/fw-modules/fw-core-vue/ui/components/dashboard/DashboardHero'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'
export default {
  components: {
    DashboardHero,
    Person,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    absence: {
      type: Object,
      default: () => {},
    },
    justification: {
      type: Object,
      default: () => {},
    },
    users: {
      type: Object,
      default: () => {},
    },
    justificationTypes: {
      type: Object,
      default: () => {},
    },
    justificationStatus: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      activeStatusInfo: null,
    }
  },

  computed: {
    selectedJustificationType() {
      return this.justification.motive ? this.justificationTypes[this.justification.motive] : {}
    },
  },

  methods: {
    openStatusInfoModal(data) {
      this.activeStatusInfo = data
    },

    closeModal() {
      this.activeStatusInfo = null
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "notDefined": "Não definido",
    "people": "Pessoas",
    "manager": "Manager",
    "subManagers": "Autorizadores",
    "motive": "Motivo",
    "phaseOngoing": "A decorrer",
    "phasePending": "Pendente",
    "phaseAvailable": "Disponível",
    "phaseFinished": "Terminado",
    "close": "Fechar",
    "motives": {
      "wedding": "Casamento",
      "deathOfRelativeOrKin": "Falecimento de familiar ou afins",
      "nonAttributableFacts": "Factos não imputáveis ao/à trabalhador/a",
      "medical": "Pela necessidade de tratamento ambulatório, realização de consultas médicas ou exames complementares de diagnóstico",
      "educationalSituationOfMinor": "Deslocação à escola para se inteirar da situação educativa de filho/a menor",
      "firstDayOfSchool": " Acompanhamento de filho/a menor de 12 anos no primeiro dia de escola",
      "bloodDonationFirstAid": "Doação de sangue e socorrismo",
      "procedure": "Necessidade de submissão a métodos de seleção em procedimento concursal",
      "electedWorkers": "Trabalhadores/as eleitos/as para estruturas de representação coletiva",
      "publicOfficeCandidate": "Candidatos/as a eleições para cargos públicos durante o período legal da campanha eleitoral",
      "prenatalConsultations": "Dispensa para consultas pré-natais",
      "timeCreditUse": "Gozo de crédito de horas do mês anterior",
      "externalService": "Serviço externo no(s) dia(s)",
      "occasionalInterruptions": "Interrupções ocasionais",
      "annualCredit": "Crédito Anual",
      "other": "Outras faltas previstas na Lei"
    },
    "absenceComunication": {
      "label": "Comunicação da(s) falta(s)",
      "in_advance": "Com a antecedência mínima de 5 dias",
      "not_predictable": "Não era(m) previsível(eis)"
    },
    "activeStatusInfoText": {
      "draft": "O estado <b>Rascunho</b> representa a fase inicial de uma justificação, .... . O estado seguinte, <b>Submetida</b>, estará disponível caso sejam cumpridos os seguintes requisitos:",
      "submitted": "O estado <b>Submetido</b>",
      "reviewed": "O estado <b>Revisto</b>",
      "manager_reviewed": "O estado <b>Revisto pelo responsável</b>",
      "closed": "O estado <b>Encerrado</b> determina o fim do ."
    },
    "statusInfo": "Os estados são sequênciais e só podem ser alterados a partir de ações (disponíveis no lado direito neste cabeçalho)",
    "status": {
      "label": "Estado",
      "draft": "Rascunho",
      "submitted": "Submetida",
      "approved": "Aprovada",
      "evaluated": "Avaliada",
      "denied": "Rejeitada",
      "reviewed": "Revisto",
      "manager_reviewed": "Revisto pelo responsável",
      "closed": "Fechada"
    }
  },
  "en": {
    "notDefined": "Not defined",
    "people": "People",
    "manager": "Manager",
    "subManagers": "Sub managers",
    "motive": "Motive",
    "phaseOngoing": "Ongoing",
    "phasePending": "Pending",
    "phaseAvailable": "Available",
    "phaseFinished": "Finished",
    "close": "Close",
    "activeStatusInfoText": {
      "draft": "The <b>Draft</b> status represents the initial phase of a justification, for .... The next state, <b>Submitted</b>, will be available if the following requirements are met:",
      "submitted": "The <b>Submitted</b> status",
      "reviewed": "The <b>Reviewed</b> status",
      "manager_reviewed": "The <b>Reviewed by manager</b> status",
      "closed": "The <b>Closed</b> status determines the end of ."
    },
    "statusInfo": "The statuses are sequential and can only be changed from actions (available on the right side in this header)",
    "motives": {
      "wedding": "Wedding",
      "deathOfRelativeOrKin": "Death of relative or kin",
      "nonAttributableFacts": "Factors not attributable to the employee",
      "medical": "For the need for outpatient treatment, medical appointments, or complementary diagnostic tests",
      "educationalSituationOfMinor": "Trip to the school to find out about the educational situation of a minor child",
      "firstDayOfSchool": "Accompaniment of a child under 12 on the first day of school",
      "bloodDonationFirstAid": "Blood donation and first aid",
      "procedure": "Need to submit to selection methods in a competitive bidding procedure",
      "electedWorkers": "Workers elected to collective representation structures",
      "publicOfficeCandidate": "Candidates for public office during the legal period of the election campaign",
      "prenatalConsultations": "Exemption for prenatal consultations",
      "timeCreditUse": "Use of time credit from the previous month",
      "externalService": "External service on the day(s)",
      "occasionalInterruptions": "Occasional interruptions",
      "annualCredit": "Annual Credit",
      "other": "Other absences foreseen in the law"
    },
    "absenceComunication": {
      "label": "Notification of absence(s)",
      "in_advance": "At least 5 days in advance",
      "not_predictable": "Was (were) not foreseeable"
    },
    "status": {
      "label": "Status",
      "draft": "Draft",
      "submitted": "Submitted",
      "approved": "Approved",
      "evaluated": "Evaluated",
      "denied": "Rejected",
      "reviewed": "Reviewed",
      "manager_reviewed": "Reviewed by manager",
      "closed": "Closed"
    }
  }
}
</i18n>
